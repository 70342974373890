import {
	ChatIcon,
} from '@heroicons/react/outline'
import {
	ChevronRightIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "General IT Chat",
	desc: "Ask questions about any general IT topic.",
	category: "General",
	Icon: ChatIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "blue-600",
	toColor: "gray-500",

	to: "/ai/generalit",
	api: "/ai/generalit",

	output: {
		title: "IT Guru Output",
		desc: "General Troubleshooting",
		Icon: ChevronRightIcon,
		color: "blue",
	},

	prompts: [{
		title:"Support Chatbot",
		desc: "This chat session will use 1 credit and allow you to send up to 12 messages. The bot will remember the context.",
		// n: 1,
		prompts: [{ 
				title: "Give as much detail as possible:", 
				attr: "content",  
				value: "", 
				placeholder: "My HP Probook laptop won't turn on...", 
				label: "",
				type: "textarea",
				maxLength: 2000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "How do I stop Windows from turning my monitor yellow at night?",
			},
		],
		example: {
			output: `Windows has a feature called Night Light that reduces the amount of blue light emitted by your screen during the evening hours. However, if you find it too yellow or distracting, you can turn it off by following these steps:

1. Click on the Start menu and then click on the Settings icon.
2. Click on System.
3. Click on Display.
4. Scroll down and click on Night light settings.
5. Turn off the toggle switch for "Night light."
			
This will disable the Night Light feature and your monitor should no longer have a yellow tint during the evening hours.
			`,
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

