import React, { Component } from 'react';
import Button from '../Components/Button';
import { KeyIcon } from '@heroicons/react/outline';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';

@inject('store')
@observer
class ResetForgottenPassword extends Component {
  state = {
    token: '',
    newPassword: '',
    confirmPassword: '',
    message: '',
    passwordUpdated: false,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      this.setState({ token });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /\W/.test(password);

    const complexityCount = [hasUpperCase, hasLowerCase, hasNumber, hasSymbol].filter(Boolean).length;

    return password.length >= 8 && complexityCount >= 3;
  };

  handleSubmit = async () => {
    if (!this.validatePassword(this.state.newPassword)) {
      this.setState({
        message:
          'Password must have at least 8 characters and meet 3 of the following 4 criteria: uppercase, lowercase, number, symbol.',
      });
      return;
    }

    try {
      const response = await this.props.store.api.post('/password/reset-forgotten-password', {
        token: this.state.token,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
      });

      if (response.status === 200) {
        this.setState({ message: 'Your password has been successfully updated.', passwordUpdated: true });
      } else {
        this.setState({ message: response.data.message });
      }
    } catch (err) {
      console.log(err);
      this.setState({ message: err.response.data.message || 'An error occurred. Please try again later.' });
    }
  };

  render() {
    return (
      <div className="min-h-screen bg-gradient-to-r from-gray-800 to-gray-600 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white p-6 rounded-lg shadow-md">
          <div className="text-center">
            <img className="mx-auto h-16 w-auto mb-4" src="/logo.png" alt="Logo" />
            <h2 className="mt-6 text-center text-2xl font-extrabold be9e44">
              Reset Password
            </h2>
          </div>
          <input
            type="password"
            name="newPassword"
            placeholder="New Password"
            value={this.state.newPassword}
            onChange={this.handleChange}
            className="w-full mb-4 p-3 rounded-lg border border-gray-300 focus:border-indigo-500 focus:outline-none"
            />
            <input
                     type="password"
                     name="confirmPassword"
                     placeholder="Confirm Password"
                     value={this.state.confirmPassword}
                     onChange={this.handleChange}
                     className="w-full mb-44 p-3 rounded-lg border border-gray-300 focus:border-indigo-500 focus:outline-none"
                   />
            <Button title="Reset Password" Icon={KeyIcon} onClick={this.handleSubmit} />
            {this.state.message && (
            <p
            className={`mt-4 ${ this.state.passwordUpdated ? "text-green-500" : "text-red-500" }`}
            >
            {this.state.message}
            </p>
            )}
            {this.state.passwordUpdated && (
            <p className="mt-4">
            <Link to="/login" className="text-indigo-500 hover:text-indigo-700">
            Go to Login
            </Link>
            </p>
            )}
            </div>
            </div>
            );
            }
            }
            
            export default ResetForgottenPassword;