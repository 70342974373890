import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import bot from './../assets/bot.svg';
import user from './../assets/user.svg';
import logo from "./../assets/logo.png";
import sendIcon from "./../assets/send.svg";
import styles from "./../assets/style.module.css";
import "./../assets/chatbot-global.css";

const Chatbot = ({ initialPrompt }) => {
  const chatContainerRef = useRef();
  const stopBtnContainerRef = useRef();
  const formRef = useRef();
  const textareaRef = useRef();
  const resetBtnRef = useRef();
  const [isApi, setisApi] = useState(false);
  const userMessageCount = useRef(0);
  const [charCount, setCharCount] = useState(0);

  let loadInterval;

  const handleReset = useCallback(async () => {
    const sessionId = localStorage.getItem('sessionId');

    await fetch('https://k2so.itguru.ai/reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-session-id': sessionId
      },
      credentials: 'include',
      body: JSON.stringify({ sessionId })
    });
  }, []);

  const handleResetAndReload = useCallback(async () => {
    window.location.reload();
  }, []);

  const loader = useCallback((element, profileImage) => {
    profileImage.classList.add(styles.spinning);
    element.textContent = '';

    loadInterval = setInterval(() => {
      element.textContent += '.';

      if (element.textContent === '....') {
        element.textContent = '';
      }
    }, 300)
  }, []);

  const typeText = useCallback((element, text, callback, stop) => {
    let index = 0;
    let typingSpeed = 0;
    let charsSinceLastSpeedUpdate = 0;

    const updateTypingSpeed = () => {
      const minSpeed = 0.1;
      const maxSpeed = 10;
      typingSpeed = Math.floor(Math.random() * (maxSpeed - minSpeed + 1)) + minSpeed;
    };

    const pauseCursor = (minDuration, maxDuration, callback) => {
      const duration = Math.floor(Math.random() * (maxDuration - minDuration + 1)) + minDuration;
      setTimeout(() => {
        callback();
      }, duration);
    };

    const typeChar = () => {
      if (index < text.length && !stop()) {
        // Remove cursor before adding the next character
        element.innerHTML = element.innerHTML.replace('|', '');

        // Add the character and cursor
        element.innerHTML += text.charAt(index) + '|';
        index++;
        charsSinceLastSpeedUpdate++;

        // Scroll down to the bottom of the chat container after every X characters
        if (index % 75 === 0) {
          if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
          }
        }

        // Update typing speed after X+ characters
        if (charsSinceLastSpeedUpdate >= 25) {
          const shouldUpdateSpeed = Math.random() > 0.8;
          if (shouldUpdateSpeed) {
            updateTypingSpeed();
            charsSinceLastSpeedUpdate = 0;
          }
        }

        // Determine if the cursor should pause
        const shouldPause = Math.random() > 0.99;

        // Schedule the next character with a variable delay
        if (shouldPause) {
            pauseCursor(50, 400, () => { //Adjust the duration in ms of the the paused cursor (min, max, ...)
            setTimeout(typeChar, typingSpeed);
          });
        } else {
          setTimeout(typeChar, typingSpeed);
        }
      } else {
        // Added to scroll down page after completion
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }

        // Remove cursor when typing is done
        element.innerHTML = element.innerHTML.replace('|', '');

        // Call the callback function when typing is completed
        if (callback) {
          callback();
        }
        // Hide the "Stop generating" button
        stopBtnContainerRef.current.style.display = 'none';
      }
    };

    // Add cursor initially
    element.innerHTML += '|';

    // Set initial typing speed
    updateTypingSpeed();

    // Start typing with the first character
    typeChar();
  }, []);

  function generateUniqueId() {
    const timestamp = Date.now();
    const randomNumber = Math.random();
    const hexadecimalString = randomNumber.toString(16);
    return `id-${timestamp}-${hexadecimalString}`;
  }

  function chatStripe(isAi, value, uniqueId, isErrorState = false) {
    const wrapper = document.createElement("div");
    wrapper.classList.add(styles.wrapper);
    if (isAi) {
      wrapper.classList.add(styles.ai);
    }
  
    wrapper.innerHTML = `
      <div class="${styles.chat}">
        <div class="${styles.profile}">
          <img
            src="${isAi ? bot : user}"
            alt="${isAi ? "bot" : "user"}"
          />
        </div>
        <div class="${styles.message}" id=${uniqueId}>${value}</div>
      </div>
    `;
  
    const profileDiv = wrapper.querySelector(`.${styles.profile}`);
    if (isErrorState) {
      profileDiv.style.backgroundColor = '#c0392b'; //Change the color (red) of the profile image when the max message limit is reached or if an error occurs
    }
  
    return wrapper;
  }

  const handleInput = (e) => {
    const input = e.target.value;
    setCharCount(input.length);
  };

  const apiUrl = useMemo(() => {
      if (window.location.pathname.includes('/security')) {
        return 'https://k2so.itguru.ai/securitysupport';
      } else if (window.location.pathname.includes('/generalnet')) {
        return 'https://k2so.itguru.ai/networksupport';
      } else {
        return 'https://k2so.itguru.ai/chatbot';
      }
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
    e.preventDefault();

    const data = new FormData(formRef.current);

    //Checks if pormpt is empty and then resets the form and cancels the submit if so
    const prompt = data.get('prompt').trim();
    if (!prompt) {
      formRef.current.reset();
    return;
    }

    // Check if the user has reached the maximum message limit
    if (userMessageCount.current >= 12) {
      const maxLimitMessage = 'Message limit reached. Please press Reset to start a new topic.';
      const maxLimitChatStripe = chatStripe(true, maxLimitMessage, null, true); // 4th param is true which sets profile color to red
      chatContainerRef.current.appendChild(maxLimitChatStripe); // displays message in the chatstripe
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight; // scroll down
      // Disable the submit button and the Enter key listener
      const submitButton = document.querySelector('button[type="submit"]');
      submitButton.disabled = true;
      submitButton.style.opacity = "0.3";
      setisApi(true); // This will prevent the Enter key listener from triggering handleSubmit
      return;
    }

    // Reset the character count to zero
    setCharCount(0);

    // Reset the textarea height to its initial value after submitting the form
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.style.minHeight}px`;

    // Get the session ID from the browser localstorage, or generate a new one
    let sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
    sessionId = generateUniqueId();
    localStorage.setItem('sessionId', sessionId);
    }

    // Add the session ID to the request body
    const body = { prompt: prompt, sessionId };

    // user's chatstripe
    const userChatStripe = chatStripe(false, prompt);
    chatContainerRef.current.appendChild(userChatStripe);

    // Update the user message count after message submission
    userMessageCount.current += 1;

    formRef.current.reset();

    // bot's chatstripe
    const uniqueId = generateUniqueId();
    const botChatStripe = chatStripe(true, " ", uniqueId);
    chatContainerRef.current.appendChild(botChatStripe);

    // scroll down
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;

    // Begin loading animations
    const messageDiv = document.getElementById(uniqueId);
    const profileImage = botChatStripe.querySelector(`.${styles.profile} img`);
    loader(messageDiv, profileImage);

    const submitButton = document.querySelector('button[type="submit"]');
    setisApi(true); // to show the loader for profile spinner

    // Fetch data from server    
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-session-id': sessionId
      },
      credentials: 'include',
      body: JSON.stringify(body)
    })

    // Remove loading animations
    clearInterval(loadInterval);
    profileImage.classList.remove(styles.spinning);
    messageDiv.innerHTML = '';

    // Show the "Stop generating" button
    stopBtnContainerRef.current.style.display = 'block';

    let shouldStop = false;
    const stop = () => shouldStop;

    if(response.ok) {
      const data = await response.json();
      const parsedData = data.bot.content.trim();

      // Type out the AI response to the user
      typeText(messageDiv, parsedData, () => {
        setisApi(false); // to hide the loader for profile spinner
        stopBtnContainerRef.current.style.display = 'none';
      }, stop);
      const stopBtn = document.getElementById('stop-btn');
      stopBtn.onclick = () => {
      shouldStop = true;

      // Enable the submit button when stop is pressed
      submitButton.disabled = false;
      submitButton.style.opacity = "1";
    }} else {
      const err = await response.text();
      console.log(err);
      const somethingWrong = "Something went wrong. Please try again.";
      const somethingWrongChatStripe = chatStripe(true, somethingWrong, null, true);
      chatContainerRef.current.appendChild(somethingWrongChatStripe);
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      
      // Enable the submit button when there is an error
      submitButton.disabled = false;
      submitButton.style.opacity = "1";

      // Hide the "Stop generating" button
      stopBtnContainerRef.current.style.display = 'none';

    }

    },
    [loadInterval, loader, setisApi]
  );

  useEffect(() => {
    const textarea = textareaRef.current;

    const handleInput = () => {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, parseFloat(getComputedStyle(textarea).maxHeight))}px`;
    };

    const handleWheel = (event) => {
      const deltaY = event.deltaY > 0 ? 1 : -1;
      textarea.scrollTop += deltaY * 30;
      event.preventDefault();
    };

    textarea.addEventListener('input', handleInput);
    textarea.addEventListener('wheel', handleWheel);

    return () => {
      textarea.removeEventListener('input', handleInput);
      textarea.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    const form = formRef.current;

    const handleKeydown = (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        if (!isApi) {
          handleSubmit(e);
        } else {
          e.preventDefault(); // Prevent the default behavior of the Enter key while the AI is loading
        }
      }
    };

    form.addEventListener('submit', handleSubmit);
    form.addEventListener('keydown', handleKeydown);

    return () => {
      form.removeEventListener('submit', handleSubmit);
      form.removeEventListener('keydown', handleKeydown);
    };
  }, [handleSubmit, isApi]);

  const [pageLoaded, setPageLoaded] = useState(false);

  // Work around for initialPrompt being empty on initial component render.
  useEffect(() => {
    if (!initialPrompt) {
    setPageLoaded(true);
    }
  }, []);

  // UseEffect hook for handling initial component load
  useEffect(() => {
    if (initialPrompt) {
      (async () => {
        await handleReset();
        const event = new Event("submit", { cancelable: true });
        textareaRef.current.value = initialPrompt.trim();
        await handleSubmit(event);
      })();
    }
  }, [handleReset, handleSubmit, pageLoaded]);

  // Reset button click handler
  useEffect(() => {
    const resetBtn = resetBtnRef.current;
    resetBtn.addEventListener('click', handleResetAndReload);
    return () => {
      if (resetBtn) {
        resetBtn.removeEventListener('click', handleResetAndReload);
      }
    };
  }, [handleResetAndReload, resetBtnRef]);

  return (
    <div className="chatbot">
      <header>
        <img src={logo} alt="IT Guru Logo" className={styles.logo} />
      </header>
      <div id="chat_container" ref={chatContainerRef} className={styles.chat_container}></div>
      <div id="stop-btn-container" style={{ display: "none" }} ref={stopBtnContainerRef} className={styles.stopBtnContainer}>
        <button id="stop-btn" className={styles.stopBtn}>Stop generating</button>
      </div>
      <form ref={formRef}>
        <div className={styles.inputContainer}>
          <textarea
            name="prompt"
            rows="1"
            cols="1"
            placeholder="Ask IT Guru..."
            ref={textareaRef}
            className={styles.textarea}
            maxLength={2000}
            onInput={handleInput}
          ></textarea>
          <div className={styles.messageCountContainer}>
              Message Count: {userMessageCount.current} / 12
          </div>
          <div className={styles.charCount}>
            {charCount} / {2000}
          </div>
          <div className={styles.buttonContainer}>
          <button type="submit" className={styles.submitBtn} disabled={isApi} style={{ opacity: isApi ? 0.4 : 1 }}>
              <img src={sendIcon} alt="Send" />
            </button>
            <button id="reset-btn" ref={resetBtnRef} className={styles.resetBtn}>
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Chatbot;