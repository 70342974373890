import {
	DeviceMobileIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Mobile Device Chat",
	desc: "Support for iPhone, Android, Tablets, etc.",
	category: "Hardware",
	Icon: DeviceMobileIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "yellow-400",
	toColor: "yellow-600",

	to: "/ai/generalmobile",
	api: "/ai/generalmobile",

	output: {
		title: "IT Guru Output",
		desc: "Mobile Device Support",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Mobile Device Chatbot",
		desc: "Describe the question or issue below in detail. You will be able to ask followup questions. This chat session will use 1 credit and allow you to send up to 12 messages.",
		// n: 1,
		prompts: [{ 
				title: "Give as much detail as possible:", 
				attr: "content",  
				value: "", 
				placeholder: "How do I add my gmail to my iphone?...", 
				label: "",
				type: "textarea",
				maxLength: 2000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "How do I add my gmail to my iphone?",
			},
		],
		example: {
			output: `To add your Gmail account to your iPhone, follow these steps:

1. Open the Settings app on your iPhone.
2. Scroll down and tap on "Passwords & Accounts".
3. Tap on "Add Account".
4. Select "Google" from the list of available account types.
5. Enter your Gmail email address and tap "Next".
6. Enter your Gmail password and tap "Next".
7. Review the account permissions and toggle on any options you want to enable.
8. Tap "Save" to add your Gmail account to your iPhone.
			
Once you've added your Gmail account, you should see your emails, contacts, and calendar events synced to your iPhone.`,
			color: "blue",
		}
	}]
		
}

export default obj

