import {
	ChevronRightIcon,
} from '@heroicons/react/solid'

import {
	PresentationChartBarIcon,
} from '@heroicons/react/outline'



const obj = {

	title: "Excel Formula Wizard",
	desc: "Create, modify and debug Microsoft Excel formulas.",
	category: "Advanced",
	Icon: PresentationChartBarIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "green-500",
	toColor: " ",

	to: "/ai/code/excel",
	api: "/ai/code/excel",

	output: {
		title: "Excel Guru",
		desc: "Excel Formulas:",
		Icon: ChevronRightIcon,
		color: "gray",
	},

	prompts: [{
		title:"Excel Formula Wizard",
		desc: " ",
		// n: 1,
		prompts: [
			{ 
				title: "Create, modify, explain or debug", 
				attr: "operation",  
				value: "", 
				placeholder: "Create...", 
				label: "Type one:  Create  Modify  Explain  Debug",
				type: "text",
				maxLength: 40,
				//min: 3,
				//required: true,
				error: "",
				example: `Create`,
			},
			{ 
				title: "Description", 
				attr: "desc",  
				value: "", 
				placeholder: "", 
				label: "Describe what you need done",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				// min: 100,
				// required: true,
				error: "",
				example: "Formula for the smallest non-zero value in a range",
			},
			{ 
				title: "Formula Input: (Leave blank if creating a new formula)", 
				attr: "formula",  
				value: "", 
				placeholder: "", 
				label: "Enter the formula you need changed or checked",
				type: "code",
				maxLength: 600,
				// max: 100,
				error: "",
				example: ``,
			},
		],
		example: {
			output: ``,
			outputs: [
				"To find the smallest non-zero value in a range, you can use the following formula:",
				"`=SMALL(IF(range<>0,range),1)`",
				"Please note that when using the following formula, you need to replace the word 'range' with the actual cells that you want to use in the formula. The formula is designed to find the smallest non-zero value within a range of cells. To achieve this, the formula uses the SMALL function in combination with the IF function to create an array of non-zero values, and then return the smallest value within that array.",
				"Note that this is an array formula, so you need to press `Ctrl + Shift + Enter` instead of just `Enter` when entering it into a cell."
			],
			// Icon: TerminalIcon,
			// color: "gray",
		}
	}]
		
}

export default obj

