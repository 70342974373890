import React, { Component } from 'react';
import { KeyIcon, MailIcon } from '@heroicons/react/outline';
import { Grid, Col } from '../Components/Body';
import PasswordPrompt from '../Components/PasswordPrompt';
import Button from '../Components/Button';
import Output from '../Components/Output';
import { observable, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class PasswordReset extends Component {
  @observable email = '';
  @observable currentPassword = '';
  @observable newPassword = '';
  @observable confirmPassword = '';
  @observable emailError = '';
  @observable output = '';
  @observable loading = false;
  @observable currentPasswordError = '';
  @observable newPasswordError = '';
  @observable confirmPasswordError = '';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /\W/.test(password);

    const complexityCount = [hasUpperCase, hasLowerCase, hasNumber, hasSymbol].filter(Boolean).length;

    return password.length >= 8 && complexityCount >= 3;
  };

  handleResetPassword = async () => {
    // Check newPassword complexity and confirmPassword match before sending the request
  if (!this.validatePassword(this.newPassword)) {
    this.newPasswordError = 'Password must have at least 8 characters and meet 3 of the following 4 criteria:\nUppercase, Lowercase, Number, Symbol.';
    return;
  }

  if (this.newPassword !== this.confirmPassword) {
    this.confirmPasswordError = "New password and confirm password do not match.";
    return;
  }
    
    this.emailError = '';
    this.currentPasswordError = '';
    this.newPasswordError = '';
    this.confirmPasswordError = '';
    
    try {
      const response = await this.props.store.api.post('/password/reset-password', {
        email: this.email,
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      });
  
      if (response.status === 200) {
        this.output = 'Your password has been successfully updated.';
        console.log(response);
  
        // Clear error messages when the password update is successful
        this.emailError = '';
        this.currentPasswordError = '';
        this.newPasswordError = '';
        this.confirmPasswordError = '';
        
      } else {
        this.output = response.data.message;
      }
  
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.message) {
        this.output = err.response.data.message;
        switch (err.response.data.field) {
          case 'email':
            this.emailError = err.response.data.message;
            break;
          case 'currentPassword':
            this.currentPasswordError = err.response.data.message;
            break;
          case 'newPassword':
            this.newPasswordError = err.response.data.message;
            break;
          case 'confirmPassword':
            this.confirmPasswordError = err.response.data.message;
            break;
          default:
            break;
        }
      } else {
        this.output = 'There seems to have been an error, please try again.';
      }
    }
  };  

  onClick = async () => {
    this.loading = true;
    this.output = '';
    await this.handleResetPassword();
    this.loading = false;
  };

  onChangeEmail = e => {
    this.email = e.target.value;
  };

  onChangeCurrentPassword = e => {
    this.currentPassword = e.target.value;
  };

  onChangeNewPassword = e => {
    this.newPassword = e.target.value;
  };

  onChangeConfirmPassword = e => {
    this.confirmPassword = e.target.value;
  };

  render() {
    return (
      <>
        <Grid>
          <Col span="6">
            <PasswordPrompt
              prompt={{
                title: 'Email',
                desc: 'Enter your email address.',
                Icon: MailIcon,
              }}
              disabled={false}
            >
              <input
                type="email"
                className="mt-6 focus:outline-none text-lg bg-white rounded-md px-4 py-2 min-w-full border border-gray-300 font-regular"
                onChange={this.onChangeEmail}
                value={this.email}
                placeholder="Email address"
              />
              {this.emailError && (
                <div className="text-xs text-red-600 mt-2">{this.emailError}</div>
              )}
            </PasswordPrompt>

            <PasswordPrompt
              prompt={{
                title: 'Current Password',
                desc: 'Enter your current password.',
              }}
              disabled={false}
            >
              <input
                type="password"
                className="mt-6 focus:outline-none text-lg bg-white rounded-md px-4 py-2 min-w-full border border-gray-300 font-regular"
                onChange={this.onChangeCurrentPassword}
                value={this.currentPassword}
                placeholder="Current password"
              />
              {this.currentPasswordError && (
                <div className="text-xs text-red-600 mt-2">{this.currentPasswordError}</div>
              )}
            </PasswordPrompt>

            <PasswordPrompt
              prompt={{
                title: 'New Password',
                desc: 'Enter your new password.',
              }}
              disabled={false}
            >
              <input
                type="password"
                className="mt-6 focus:outline-none text-lg bg-white rounded-md px-4 py-2 min-w-full border border-gray-300 font-regular"
                onChange={this.onChangeNewPassword}
                value={this.newPassword}
                placeholder="New password"
              />
              {this.newPasswordError && (
                <div className="text-xs text-red-600 mt-2">{this.newPasswordError}</div>
              )}
            </PasswordPrompt>

            <PasswordPrompt
              prompt={{
                title: 'Confirm New Password',
                desc: 'Confirm your new password.',
              }}
              disabled={false}
            >
              <input
                type="password"
                className="mt-6 focus:outline-none text-lg bg-white rounded-md px-4 py-2 min-w-full border border-gray-300 font-regular"
                onChange={this.onChangeConfirmPassword}
                value={this.confirmPassword}
                placeholder="Confirm new password"
                />
                {this.confirmPasswordError && (
                <div className="text-xs text-red-600 mt-2">{this.confirmPasswordError}</div>
                )}
                </PasswordPrompt>
                <Button
                title="Update Password"
                Icon={KeyIcon}
                onClick={this.onClick}
              />
            </Col>
            <Col span="6">
              <Output
                title="Password Update"
                desc="Password update status"
                Icon={KeyIcon}
                fromColor="blue-300"
                toColor="blue-400"
                loading={this.loading}
                output={this.output}
              />
            </Col>
          </Grid>
        </>
);
}
}

export default PasswordReset;