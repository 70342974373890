import { ThemeProvider } from 'styled-components'
import React, { Component } from 'react';

import { Provider  } from 'mobx-react'
import { observer,  } from 'mobx-react'

import AppStore from './store'
import colors from 'tailwindcss/colors' 
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import Header from './Header'
import Search from './Search'
import Pricing from './Pricing'

import Dashboard from './Dashboard'

import Tool from './Core/Tool'
import CustomTool from './Core/CustomTool'
import Chat from './Core/Chat'

import Login from './Login/Login'

import Profile from './Profile/'
import LoginSuccess from './Login/Success'

import ForgotPassword from './Profile/ForgotPassword';
import ResetForgottenPassword from './Profile/ResetForgottenPassword';


import './App.scss'

if(!window.store){
  window.store = new AppStore();
}


@observer
class App extends Component {
  render() {
    return (
    <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
           {window.store.redirect ? <Redirect to={window.store.redirect} /> : null }
            {window.store.isLoggedIn ? <>
            {window.store.profile.status ? <>  {/*  Logged in with plan */}
                <Switch>
                    <Route path="/writing/document"><div/></Route>
                    <Route component={Header} />
                </Switch>
               
                <Switch>
                      
                      <Route path="/" exact component={Dashboard} />
                      <Route path="/search" exact component={Search} />
                      <Route path="/Core/CustomTool" component={CustomTool} />

                      <Route path="/ai/" render={(props) => {
                          const title = new URLSearchParams(props.location.search).get("title");
                          return (
                            <Switch>
                              <Route path="/ai/code/debugging" component={Chat} />
                              <Route component={(title === 'General IT Chat' || title === 'Mobile Device Chat' || title === 'General Networking Chat' || title === 'Virus and Security Support') ? CustomTool : Tool} />
                            </Switch>
                          );
                        }}>
                      </Route>
                      <Route path="/my-profile" component={Profile} />
                      <Route path="/signup/failed" component={Profile} />
                      <Route path="/signup/success" component={LoginSuccess} />
                      <Route path="/signup/success" component={LoginSuccess} />
                    
                  </Switch>
                  </> : <> {/* Logged in but no plan */}
               <Switch>
                  <Route path="/signup/success" component={LoginSuccess} />
                  <Route>
                    <Pricing />
                  </Route>
                  </Switch>
                </>} </> : <> {/*  Not Logged In */}
                <Switch>
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/reset-forgotten-password" component={ResetForgottenPassword} />
                  <Route path="/" exact>
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/" component={Login} />
                </Switch>
            </>}
           </Router>
        </Provider>
      </ThemeProvider>
    )
  }
}

export default App