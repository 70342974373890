import React, { Component } from 'react';
import Button from '../Components/Button';
import { MailIcon } from '@heroicons/react/outline';
import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class ForgotPassword extends Component {
  state = {
    email: '',
    message: '',
    disabled: false,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async () => {
    this.setState({ disabled: true });
    setTimeout(() => {
      this.setState({ disabled: false });
    }, 20000);

    try {
      await this.props.store.api.post('/password/forgot-password', {
        email: this.state.email,
      });

      this.setState({
        message:
          'If this email address is valid, then a password reset email has been sent. If you do not receive an email, please double check the email address field and try again.',
      });
    } catch (err) {
      console.log(err);
      this.setState({
        message:
          'If this email address is valid, then a password reset email has been sent. If you do not receive an email, please double check the email address field and try again.',
      });
    }
  };

  render() {
    return (
      <div className="min-h-screen bg-gradient-to-r from-gray-800 to-gray-600 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white p-6 rounded-lg shadow-md">
          <div className="text-center">
            <img className="mx-auto h-16 w-auto mb-4" src="/logo.png" alt="Logo" />
            <h2 className="mt-6 text-center text-2xl font-extrabold be9e44">
              Reset Password
            </h2>
          </div>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleChange}
            className="w-full mb-4 p-3 rounded-lg border border-gray-300 focus:border-indigo-500 focus:outline-none"
          />
          <Button
            title="Send Password Reset Link"
            Icon={MailIcon}
            onClick={this.handleSubmit}
            disabled={this.state.disabled}
          />
          {this.state.message && <p className="mt-4 be9e44">{this.state.message}</p>}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
